import UAParser from 'ua-parser-js';
import { memoize } from 'lodash';
import theme, { Breakpoints } from 'styles/theme';
import URL from 'url';
import { tryIntParse } from './utils';

export const parser = new UAParser();

// Since this function is memoized and has no parameters, it will always return the same value until a new instance of the function is run (e.g. on page refresh)
export const isMobileOrTablet: () => boolean = memoize(() => {
  const { type } = parser.getDevice();
  const { name } = parser.getOS();
  // Some android tablets appear as an undefined device type, in this case, look at OS name instead.
  return type === 'mobile' || type === 'tablet' || name === 'Android';
});

export const getUserAgentHeader = () => parser.getUA();

export const hasDesktopWidth = () => getViewportWidth() >= tryIntParse(theme.breakpoints.desktop);

export const hasPortraitOrientation = (): boolean => getViewportHeight() > getViewportWidth();

export const hasMobileOrTabletPortraitWidth = () =>
  getViewportWidth() < tryIntParse(theme.breakpoints.tabletLandscape);

export const getViewportWidth = (): number => {
  if (typeof window !== 'undefined') {
    return window.innerWidth ?? document?.documentElement?.clientWidth ?? 0;
  }
  return 0;
};

export const getViewportHeight = (): number => {
  if (typeof window !== 'undefined') {
    return window.innerHeight ?? document?.documentElement?.clientHeight ?? 0;
  }
  return 0;
};

export const getDevice = () => {
  const { type, model, vendor } = parser.getDevice();
  /*
    As 'desktop' is not a supported device type, we conclude that if a type isn't found, then it must be desktop.
    Link to the ua-parser-js documentation regarding this issue: https://github.com/faisalman/ua-parser-js#-getdeviceidata.
  */
  const filteredType = type ? type.toUpperCase() : 'Desktop';
  return { type: filteredType, model: model ?? null, vendor: vendor ?? null };
};

export const getOS = () => {
  const { name, version } = parser.getOS();
  return { name: name ?? null, version: version ?? null };
};

export const getSupportedMobileOSName = (): 'ios' | 'android' | null => {
  const { name } = parser.getOS();
  if (name !== 'iOS' && name !== 'Android') return null;
  return name.toLowerCase() as 'ios' | 'android';
};

const removeWhitespaceAndLower = (element: string) => element.replace(/\s/g, '').toLowerCase();

export const getStreamParams = (): string => {
  const params = ['web'];
  if (typeof window !== 'undefined') {
    const { userAgent } = navigator;
    if (userAgent) {
      const ua = new UAParser(userAgent);
      const browserName = ua.getBrowser().name || null;
      const os = ua.getOS().name || null;
      browserName && params.push(removeWhitespaceAndLower(browserName));
      os && params.push(removeWhitespaceAndLower(os));
    }
  }

  return params.join(',');
};

export const isWithinMobileBreakpoint = () => getCurrentBreakpointValue() === Breakpoints.mobile;

export const getCurrentBreakpointValue = memoize((): Breakpoints => {
  const width = getViewportWidth();
  // This function assumes the Breakpoints enum values are sorted in ascending order
  const breakpointTier = Object.values(Breakpoints)
    .reverse()
    .find((breakpoint) => width >= tryIntParse(breakpoint));
  return breakpointTier ?? Breakpoints.mobile;
});

export const getCurrentBreakpoint = (): string => {
  const width = getViewportWidth();
  const breakpointsObject = Object.entries(Breakpoints);
  /* 
    Must reverse the list as find returns the first instance of truth, any value lower than 'width'
    would be true, hence find the biggest 'true' value.
  */
  const currentBreakpoint =
    breakpointsObject.reverse().find(([, value]) => width >= tryIntParse(value)) ?? breakpointsObject[0];
  return currentBreakpoint[0];
};

export function disableScroll(e: TouchEvent | WheelEvent) {
  e.preventDefault();
  e.stopPropagation();
}

/*
  The OCID is an ID thats dropped down when engaging via ads (e.g. an ad for the block on facebook,
  a user clicks that to a news article on nine.com.au, then has an onward journey to 9now to watch the
  show - that ID gets passed down so the conversion of that campaign is tracked through multiple journeys).
  See more: https://nine.atlassian.net/wiki/spaces/9NOW/pages/2729618103/OCID+and+Referrers+for+9Now+Deeplinks
*/
export const getOCID = () => {
  const {
    query: { ocid },
  } = URL.parse(window.location.toString(), true);
  return ocid as string;
};

export const getGCLID = () => {
  const {
    query: { gclid },
  } = URL.parse(window.location.toString(), true);
  return gclid as string;
};

export const getCookie = (cookieName: string): string | null => {
  const cookieKey = `${cookieName}=`;
  const cookieString = document.cookie;
  const cookieArray = cookieString ? cookieString.split(';') : [];
  const matchingCookie = cookieArray.find((cookie) => cookie.trim().startsWith(cookieKey));
  return matchingCookie ? matchingCookie.split('=')[1] : null;
};

export const isUnsupported = () => {
  const { browser = {} as Record<string, string> } = parser.getResult();
  const { name = '', version: _version = '0' } = browser as { name: string; version: string };
  const version = parseInt(_version, 10);
  return (
    name.includes('IE') ||
    (name === 'Mobile Safari' && version < 8) ||
    (name === 'Safari' && version < 8) ||
    // default browser on newer Android phones (Samsung, LG, Sony etc.)
    // @see http://www.quirksmode.org/blog/archives/2015/02/chrome_continue.html
    // @see http://www.quirksmode.org/blog/archives/2015/02/counting_chromi.html
    (name === 'Chrome' && version <= 35) ||
    name === 'Android Browser'
  );
};

export function getChromiumMajorVersion(userAgentString: string): number | null {
  const userAgentRegex = /(firefox|msie|chrome|safari)[/\s]([\d.]+)/gi;
  const execArray = userAgentRegex.exec(userAgentString);
  const chromeMatch =
    (execArray !== null && Array.from(execArray).filter((x) => x.indexOf('Chrome') !== -1)) || [];
  if (chromeMatch.length === 0) {
    return null;
  }
  const versionNumbersIndex = 1;
  const semanticVersion = chromeMatch[0].split('/')[versionNumbersIndex];
  const majorVersionNumberIndex = 0;
  return parseInt(semanticVersion.split('.')[majorVersionNumberIndex], 10);
}

export function shouldNotifyWidevineUpdateForChromiumBrowsers() {
  const chromiumMajorVersion = getChromiumMajorVersion(navigator.userAgent);
  const chromiumVersionThatSupportsWidevine = 107;
  return chromiumMajorVersion && chromiumMajorVersion < chromiumVersionThatSupportsWidevine;
}

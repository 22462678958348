import { useAtomValue, useSetAtom } from 'jotai';
import { RefObject, useEffect } from 'react';
import { headerAtom, tabbedGridAtom } from 'src/store/store';

const useTabListObserver = (tabListRef: RefObject<HTMLUListElement>) => {
  const { headerHeight } = useAtomValue(headerAtom);
  const setTabbedGridAtom = useSetAtom(tabbedGridAtom);
  const tabListPinnedHeight = headerHeight + 1;

  // Determine whether the Tab List is fixed to the top of the screen using an observer.
  useEffect(() => {
    const tabListElement = tabListRef.current;

    if (!tabListElement) return;

    const tabListPinnedObserver = new IntersectionObserver(
      ([e]) => {
        const isTabListPinned = e.intersectionRect.top === tabListPinnedHeight;
        setTabbedGridAtom({ isPositionFixed: isTabListPinned });
      },
      { threshold: [1], rootMargin: `-${tabListPinnedHeight}px 0px 0px 0px` }
    );

    tabListPinnedObserver.observe(tabListElement);

    return () => {
      tabListPinnedObserver.unobserve(tabListElement);
    };
  }, [tabListPinnedHeight, setTabbedGridAtom, tabListRef]);
};

export default useTabListObserver;

import config from 'config';
import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { configAtom } from 'src/store/store';

export const useConfig = () => {
  const [appConfig, setAppConfig] = useAtom(configAtom);
  const isServer = typeof window === 'undefined'; // https://stackoverflow.com/a/59562136

  if (isServer) {
    setAppConfig(config);
  }

  useEffect(() => {
    // Client-side only
    // Grab the config from the document
    // This is injected into the DOM by the server in _document.tsx
    const configFromHtml = document.getElementById('appConfig')?.innerHTML;
    if (!configFromHtml) {
      // throw error
      return;
    }

    try {
      const clientConfig = JSON.parse(configFromHtml);
      setAppConfig(clientConfig);
    } catch {
      // throw error
    }
  }, [setAppConfig]);

  return appConfig;
};
